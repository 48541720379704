import React from "react"
import {
  Box,
  Heading,
  Container,
  Text,
  Flex,
  Stack,
  Image,
  Button,
  useMediaQuery,
} from "@chakra-ui/react"
import Typewriter from "typewriter-effect"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation, Pagination } from "swiper"
import "swiper/css/navigation"
import "swiper/css"
import { AiOutlineArrowRight } from "react-icons/ai"
import BannerPhoto from "../../images/prosal-hero.png"
import bannertData from "../../json/home/banner.json"

const Banner = () => {
  const [isLowerThan768] = useMediaQuery("(max-width: 768px)")
  const [isLowerThan480] = useMediaQuery("(max-width: 480px)")
  return (
    <Container maxW={"full"} marginTop={isLowerThan480 ? "100px" : "50px"}>
      <Box h={isLowerThan768 ? "100vh" : "80vh"}>
        <Swiper
          className="mySwiper"
          auto={true}
          items={1}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
        >
          <SwiperSlide>
            <Stack
              w={"full"}
              maxW={"full"}
              direction={{ base: "column", md: "row" }}
              pt="50px"
            >
              <Flex p={1} flex={1} align={"center"}>
                <Stack spacing={6} w={"full"} maxW={{ base: "full", md: "lg" }}>
                  <Heading
                    fontSize={{ base: "3xl", md: "4xl", lg: "5xl" }}
                    textAlign={{ base: "center", md: "left" }}
                  >
                    <Text as={"span"} position={"relative"}>
                      {bannertData.bannerTitle}
                    </Text>
                    <br />{" "}
                    <Text color={"#04a073"} as={"span"}>
                      <Typewriter
                        options={{
                          strings: [
                            bannertData.highlightedTitle,
                            bannertData.highlightedSubTitle,
                            bannertData.highlightedAnotherTitle,
                          ],
                          autoStart: true,
                          loop: true,
                        }}
                      />
                    </Text>{" "}
                  </Heading>
                  <Text
                    fontSize={{ base: "md", lg: "lg" }}
                    color={"gray.500"}
                    textAlign={{ base: "center", md: "left" }}
                  >
                    {bannertData.description}
                  </Text>
                  <Stack direction={{ base: "column", md: "row" }} spacing={4}>
                    <Button
                      rightIcon={<AiOutlineArrowRight />}
                      className="hover-btn green"
                      variant="ghost"
                      color={"#04a073"}
                      fontSize={20}
                      padding={2}
                      _hover={{
                        background: "transparent",
                      }}
                      mt="5"
                      _before={{
                        content: "''",
                        width: "2px",
                        height: "2px",
                        position: "absolute",
                        bottom: "0",
                        left: "0",
                        bg: "#04a073",
                        zIndex: 0,
                        mr: "10px",
                        transition: "width 0.5s ease-in-out",
                      }}
                      _after={{
                        content: "''",
                        width: "100%",
                        height: "2px",
                        position: "absolute",
                        bottom: "0",
                        left: "0",
                        bg: "rgba(81,181,121,0.05)",
                        zIndex: 0,
                        mr: "10px",
                      }}
                    >
                      {bannertData.bannerButtonText}
                    </Button>
                  </Stack>
                </Stack>
              </Flex>
              <Flex flex={1}>
                <Image
                  alt={"Login Image"}
                  objectFit={"contain"}
                  src={bannertData.bannerphoto}
                  width="100%"
                />
              </Flex>
            </Stack>
          </SwiperSlide>
          <SwiperSlide>
            <Box pt={{ base: 20, md: 28 }}>
              <Container maxW={"full"}>
                <Flex
                  flex={1}
                  align={"center"}
                  justify={"space-between"}
                  direction={{ base: "column", md: "row" }}
                  textAlign={{ base: "center", md: "left" }}
                  minH="50vh"
                >
                  <Stack px={{ base: 0, md: 15 }} w={"full"}>
                    <Text
                      as={"h2"}
                      color="dark"
                      lineHeight="1.3"
                      fontWeight={700}
                      fontSize={{ base: "3xl", sm: "4xl", lg: "6xl" }}
                      mb={{ base: "20px" }}
                    >
                      {bannertData.bannerSecondTitle} <br />
                      {bannertData.bannerSecondSubTitle}
                    </Text>
                  </Stack>
                  <Button
                    rightIcon={<AiOutlineArrowRight />}
                    variant="outline"
                    color={"#04a073"}
                    fontSize="18px"
                    p={"25px"}
                    fontWeight={"bold"}
                    borderWidth="2px"
                    borderColor="#04a073"
                    w={{ base: "100%", sm: "xs", md: "md" }}
                    display="flex"
                    justifyContent={"space-between"}
                    _hover={{ borderColor: "#04a073", color: "#04a073" }}
                  >
                    {bannertData.bannerSecontButtonText}
                  </Button>
                </Flex>
              </Container>
            </Box>
          </SwiperSlide>
        </Swiper>
      </Box>
    </Container>
  )
}

export default Banner
